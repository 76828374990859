import PropTypes from 'prop-types'
import React from 'react'
import {Card as SemanticUICard, Segment, Header} from 'semantic-ui-react';
import Card from './City';

const CardStack = (props) => (
    <Segment>
        <Header as={'h3'}>{props.title}</Header>
        {props.entries.length > 0 &&
        <SemanticUICard.Group>
            {props.entries.map((entry, index) => {
                return <Card key={index} entry={entry} onApproved={props.onEntryApproved}
                             onDeclined={props.onEntryDeclined}/>;
            })}
        </SemanticUICard.Group>
        }
        {props.entries.length === 0 && <span>Nothing here</span>}
    </Segment>
)

CardStack.propTypes = {
    entries: PropTypes.array,
    onEntryApproved: PropTypes.func,
    onEntryDeclined: PropTypes.func,
    title: PropTypes.string,
}

export default CardStack;