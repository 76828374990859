import PropTypes from 'prop-types'
import React from 'react'
import {Container, Dimmer, Loader} from 'semantic-ui-react'

import CityStack from './CityStack';

class PinPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: [],
            isLoading: false,
        };
    }

    _updateEntry = (entry) => {
        // Activate the loader
        this.setState({isLoading: true});

        fetch(this.props.apiURL + '/cities/' + entry._id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json charset=utf-8',
                'Content-Type': 'application/json',
                'Token': this.props.token
            },
            body: JSON.stringify(entry),
        })
            .then(res => res.json())
            .then((data) => {
                // Reload the state, changes were saved locally already anyway
                this.setState({
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({isLoading: false});
            });
    }

    componentDidMount() {
        // Activate the loader
        this.setState({isLoading: true});

        fetch(this.props.apiURL + '/cities?' + new URLSearchParams({allCards: true}), {
            headers: {'Token': this.props.token}
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({entries: data, isLoading: false});
            })
            .catch((error) => {
                console.error(error);
                this.setState({isLoading: false});
            });
    }

    render() {
        let {entries} = this.state;

        // Do some filtering
        // let entriesApproved = entries.filter(entry => entry.status === 'approved');
        // let entriesDeclined = entries.filter(entry => entry.status === 'declined');
        // let entriesPending = entries.filter(entry => entry.status === 'pending');
        return (
            <Dimmer.Dimmable as={Container} dimmed={this.state.isLoading}>
                <div className={['ui', 'dimmer', (this.state.isLoading ? 'active' : '')].join(' ')}>
                    <Loader></Loader>
                </div>
                <CityStack title='Cities' entries={entries} onEntryApproved={this.onEntryApproved}
                           onEntryDeclined={this.onEntryDeclined}/>

                {/* <CityStack title='Pending' entries={entriesPending} onEntryApproved={this.onEntryApproved}
                           onEntryDeclined={this.onEntryDeclined}/> */}
                {/* <CityStack title='Declined' entries={entriesDeclined} onEntryApproved={this.onEntryApproved}
                           onEntryDeclined={this.onEntryDeclined}/>
                <CityStack title='Approved' entries={entriesApproved} onEntryApproved={this.onEntryApproved}
                           onEntryDeclined={this.onEntryDeclined}/> */}
            </Dimmer.Dimmable>)
    }

    onEntryApproved = (entry) => {
        entry.status = 'approved';
        this._updateEntry(entry);
    }

    onEntryDeclined = (entry) => {
        entry.status = 'declined';
        this._updateEntry(entry);
    }
}

PinPage.propTypes = {
    apiURL: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
};

export default PinPage;