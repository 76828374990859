import PropTypes from 'prop-types'
import React from 'react'
import {Card as SemanticUICard} from 'semantic-ui-react'

const Card = (props) => {
    const {entry, onApproved, onDeclined} = props;
    let color = 'blue';
    switch (entry.status) {
        case 'approved':
            color = 'green';
            break;
        case 'declined':
            color = 'red';
            break;
        default:
            break;
    }
    return (<SemanticUICard color={color}>
            <SemanticUICard.Content>
                <SemanticUICard.Header>{entry.name}</SemanticUICard.Header>
                <SemanticUICard.Meta>{entry.city}, {entry.country}</SemanticUICard.Meta>
                <SemanticUICard.Description>
                {entry.message}
               
                </SemanticUICard.Description>
                {entry.lat},{entry.lng}
            </SemanticUICard.Content>
             
            <SemanticUICard.Content extra>
                <div className='ui two buttons'>
                    <div className={['ui', 'button', 'red', (entry.status === 'declined' ? '' : 'basic')].join(' ')}
                         onClick={() => onDeclined(entry)}>Decline
                    </div>
                    <div
                        className={['ui', 'button', 'green', (entry.status === 'approved' ? '' : 'basic')].join(' ')}
                        onClick={() => onApproved(entry)}>Approve
                    </div>
                </div>
            </SemanticUICard.Content>
        </SemanticUICard>
    );
}

Card.propTypes = {
    entry: PropTypes.object,
    onApproved: PropTypes.func,
    onDeclined: PropTypes.func,
};

export default Card;