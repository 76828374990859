import './App.css'
import 'semantic-ui-css/semantic.min.css'
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import {Container, Menu} from 'semantic-ui-react';
import PinPage from './components/MessagePage';
import CityPage from './components/CityPage';
// import CardPage from './components/CardPage';
// import HighscorePage from './components/HighscorePage';
// import SongPage from './components/SongPage';

// Some constants
let apiURL = '/api';
let token = 'qSj6rkmj01BSXfsSFKkuw0Spmd3WTaLF';

let location = '' + document.location;
if (location.includes('localhost')) {
    apiURL = 'http://localhost:3003';
    // apiURL = 'https://kerstival.saxion.nl/api';
}

apiURL = 'https://api-hoi21.mywebartist.eu';

const App = () => (
    <BrowserRouter basename={'/dashboard'}>
        <Container>
            <Menu stackable>
            <Link to={'/messages'}><Menu.Item>Messages</Menu.Item></Link>
            <Link to={'/cities'}><Menu.Item>Cities</Menu.Item></Link>
                {/* <Link to={'/cards'}><Menu.Item>Cards</Menu.Item></Link> */}
                {/* <Link to={'/highscores'}><Menu.Item>Highscores</Menu.Item></Link>
                <Link to={'/songs'}><Menu.Item>Songs</Menu.Item></Link> */}
            </Menu>
        </Container>
        <Container>
            <Switch>
            <Route path={'/messages'}>
                    <PinPage apiURL={apiURL} token={token}/>
                </Route>
                <Route path={'/cities'}>
                    <CityPage apiURL={apiURL} token={token}/>
                </Route>
                {/* <Route path={'/cards'}>
                    <CardPage apiURL={apiURL} token={token}/>
                </Route> */}
                {/* <Route path={'/highscores'}>
                    <HighscorePage apiURL={apiURL} token={token}/>
                </Route>
                <Route path={'/songs'}>
                    <SongPage apiURL={apiURL} token={token}/>
                </Route> */}
            </Switch>
        </Container>
    </BrowserRouter>
)

export default App;
